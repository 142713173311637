import * as React from "react";
import Layout from "../components/layout";
import Seo from "../components/seo/seo";

const ApiPage = () => {
  return (
    <Layout>
      <Seo title="ApiPage" pathname="/api" />
      <main>Api Page</main>
    </Layout>
  );
};

export default ApiPage;
